import { Component } from '@angular/core'
import { FullLayoutComponent } from '../../layout/full-layout/full-layout.component'
import { TranslateModule } from '@ngx-translate/core'

@Component({
  selector: 'app-error404',
  standalone: true,
  imports: [FullLayoutComponent, TranslateModule],
  templateUrl: './error404.component.html',
  styleUrl: './error404.component.scss'
})
export class Error404Component {}
