<app-full-layout>
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-6 col-sm-5 col-md-6 col-lg-4 col-xl-4 col-xxl-3 mb-4 mb-lg-0">
        <!-- https://www.flaticon.com/free-sticker/play_11565693?related_id=11565693 -->
        <img src="/assets/img/stickers/wolf_play.png" />
      </div>
      <div class="col-md-12 col-lg-8 col-xl-8 col-xxl-8 offset-xxl-1 d-flex align-items-center mb-5 mb-lg-0">
        <div class="bg-secondary rounded p-5 flex-grow-1">
          <div class="mb-3 text-center">
            <h1>{{ 'page-error.404.title' | translate }}</h1>
          </div>
          <div class="text-center">
            {{ 'page-error.404.description' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</app-full-layout>
